import React from "react";
import {graphql} from "gatsby";

import { ICaseStudyFeature } from "../utils/types";
import Layout from "../components/layout/layout";
import PageHeader from "../components/pageHeader";
import CaseStudyList from "../components/caseStudies/caseStudyList";

export default function CaseStudies({data}): JSX.Element {
    const {
        contentfulCaseStudiesPage,
        contentfulLayout
    } = data;

    const caseStudyFeatures: Array<ICaseStudyFeature> = contentfulCaseStudiesPage.caseStudies.map(c => ({
        slug: c.caseStudySlug,
        previewText: c.caseStudyPreview.caseStudyPreview,
        client: {
            name: c.client.clientName,
            logoURL: c.client.clientLogo.file.url
        },
        services: c.services.map(s => ({
            name: s.serviceName,
            slug: s.serviceSlug,
            definition: s.serviceDefinition,
            shortDescription: s.serviceShortDescription,
            tactics: s.serviceTactics || []
        }))
    }));

    return (
        <Layout title={contentfulCaseStudiesPage.headerTitle}>
            <PageHeader
                title={contentfulCaseStudiesPage.headerTitle}
                titleSubtext={contentfulLayout.caseStudiesLinkTitle}
                description={contentfulCaseStudiesPage.headerDescription.headerDescription} />
            <CaseStudyList caseStudyFeatures={caseStudyFeatures} />
        </Layout>
    );
}


export const query = graphql`
    query {
        contentfulCaseStudiesPage {
            headerTitle
            headerDescription {
                headerDescription
            }
            caseStudies {
                caseStudyPreview {
                    caseStudyPreview
                }
                caseStudySlug
                client {
                    clientName
                    clientLogo {
                        file {
                            url
                        }
                    }
                }
                services {
                    serviceName
                    serviceSlug
                    serviceDefinition
                    serviceShortDescription
                    serviceTactics
                }
            }
        }
        contentfulLayout {
            caseStudiesLinkTitle
        }
    }
`;