import React from "react";
import styled from "@emotion/styled";

import { ICaseStudyFeature } from "../../utils/types";
import CaseStudyFeature from "../caseStudyFeature";

const StudyList = styled.div`
    display: flex;
    max-width: 76em;
    margin: 60px auto;
    padding: 0 20px;
    flex-wrap: wrap;
`;

const Insert = styled.div`
    flex: 1 1 calc(50% - 80px);
`;

export interface ICaseStudyListProps {
    caseStudyFeatures: Array<ICaseStudyFeature>;    
}

export default function CaseStudyList(props: ICaseStudyListProps): JSX.Element {
    const {caseStudyFeatures} = props;

    return (
        <StudyList>
            {caseStudyFeatures.map(c => (
                <CaseStudyFeature key={c.slug} caseStudyFeature={c} withBorder={false} />
            ))}
            {caseStudyFeatures.length % 2 !== 0 && (
                <Insert />
            )}
        </StudyList>
    );
}